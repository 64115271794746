import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useInfiniteQuery, useQuery } from "react-query";
import { FetchTag, GetArticleList } from "../../../service/article";
import { Empty, Pagination, Select, Skeleton, Spin } from "antd";
import ArticleCard from "../../molecules/ArticleCard";
import { Sider } from "../../molecules/Sider";
import { theme } from "shared/src/theme";
import { useTranslation } from "react-i18next";
import DownIcon from "../../../assets/downIcon.svg";

import { Button, PREFECTURE_OPTIONS } from "shared";
import { useRouter } from "next/router";

const { Option } = Select;

const Wrapper = styled.section`
  max-width: 1440px;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 67px;
  ul {
    position: sticky;
    top: 157px;
  }

  & .select-wrapper {
    position: sticky;
    top: 81px;
    background-color: ${theme.base};
    z-index: 1;
    margin-left: -1px;
    margin-right: -1px;
  }

  @media (max-width: 1550px) {
    padding: 50px 5% 67px 5%;
  }
  @media (max-width: 1440px) {
    padding: 50px 9.02% 67px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 50px 5%;
  }
  @media (max-width: 1150px) {
    padding: 50px 3%;
    min-height: auto;
  }

  @media (max-width: 768px) {
    & .select-wrapper {
      margin-bottom: 14px;
      & h2 {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 360px) {
    & .select-wrapper {
      top: 60px;
    }
  }
`;

const StyledTitle = styled.h1`
  ${theme.typography.titleLarge}
  margin-bottom: 24px;
  color: ${theme.gray0};
  width: 100%;
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ArticleContainer = styled.div`
  /* min-height: 350px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 24px;

  @media (min-width: 1441px) {
    grid-column-gap: 40px;
  }

  @media (max-width: 875px) {
    grid-template-columns: 1fr 1fr;
    height: auto;
  }

  @media (max-width: 575px) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;

    & > div:first-child {
      width: 100%;
      & img {
        max-height: 227px;
      }

      & .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
      }
      margin-bottom: 32px;
    }

    & > div:nth-child(2),
    & > div:nth-child(3) {
      margin-bottom: 30px;
      width: calc(50% - 14px);
      & img {
        max-height: 132px;
      }
    }
    & > div:nth-child(2) {
      margin-right: 7.5px;
    }
    & > div:nth-child(3) {
      margin-left: 7.5px;
    }

    & > div:nth-child(n + 4) {
      margin-bottom: 16px;
      width: 100%;
      & .ant-card {
        display: flex;
      }
      & img {
        max-height: 96px;
        min-height: 96px;
        max-width: 96px;
        border-radius: 10px 0px 0px 10px !important;
        min-width: 96px;
        margin-right: 16px;
      }
      & .ant-card-body {
        padding: 0px;
      }
    }

    & > div:last-child {
      margin-bottom: 0px;
    }
  }
`;
const ShowMoreButtonWrapper = styled.div`
  display: flex;
  margin-top: 27px;
  width: 100%;
  justify-content: flex-end;
`;
const StyledLink = styled.a`
  color: ${theme.buttonRed};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  @media (min-width: 1101px) {
    position: sticky;
    top: 97px;
    background: ${theme.white};
    z-index: 100;
    padding-right: 10px;
  }
  @media (min-width: 769px) and (max-width: 1100px) {
    position: sticky;

    background: ${theme.white};
    z-index: 100;
    padding-right: 10px;
    top: 80px;
  }
  & .ant-select {
    min-width: 98px;
    height: 48px;
    margin-left: auto;
    margin-bottom: 14px;
  }
  & .ant-select-selector {
    & span {
      font-size: 16px;
    }
    height: 48px !important;
    padding: 0 12px !important;
    border-radius: 8px !important;
    border-color: ${theme.buttonRed} !important;
  }
  & .ant-select-selection-item {
    line-height: 48px !important;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.buttonRed};
    padding-right: 28px !important;
  }
  & .ant-select-arrow {
    right: 20px;
  }

  @media (min-width: 769px) {
    & .ant-select {
      display: none;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & button {
    min-height: 48px;
    padding: 10px;
    min-width: 136px;
    margin: 24px auto 0px;
  }
  @media (min-width: 1441px) {
    flex: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LoadingWrapper = styled.div<{ sm: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 769px) {
    height: auto;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const PaginationWrapper = styled.div<{
  margin?: string;
  isTopPagination?: boolean;
}>`
  display: flex;
  margin: ${(props) => props.margin};
  width: 100%;
  justify-content: flex-end;
  position: sticky;

  /* position:${(isTopPagination) => (isTopPagination ? "sticky" : "")}; */
  top: 90px;
  & .ant-pagination {
    & .ant-pagination-item {
      height: 48px;
      width: 48px;
      border: none;
      a {
        padding: 8px;
      }
    }
    & .ant-pagination-item-active {
      border: none;
      a {
        color: ${theme.white} !important;
        background: ${theme.buttonRed};
        padding: 8px;
        height: 48px;
        width: 48px;
      }
    }
    & li:nth-last-child(2) {
      color: lime !important;
      /* display: none; */
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledFetch = styled(Button)`
  @media (min-width: 768px) {
    display: none;
  }
`;
const SelectWrapper = styled.div`
  position: relative;
`;
const StyledEmptyMessage = styled.h1`
  font-size: 18px;
`;
interface Props {
  showButtons?: boolean;
  pageSize?: any;
  initialData: any;
  city?: any;
  initialTags: any;
}
export const spreadArray = (data?: any[]): any[] => {
  const newArray: any[] = [];
  if (data) {
    for (const row of data) {
      const rowData = row?.data || [];
      for (const innerData of rowData) {
        newArray.push(innerData);
      }
    }
  }
  return newArray;
};

export const ArticleList = ({
  showButtons,
  pageSize,
  city,
  initialData,
  initialTags,
}: Props) => {
  const [selectedMenu, setSelectedMenu] = useState("0");
  const [initialFetch, setInitialFetch] = useState(true);

  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const router = useRouter();
  const { data: tags } = useQuery(["fetchTag", city], FetchTag, {
    refetchOnWindowFocus: false,
    initialData: initialTags,
  });

  const getCount = (allData) => {
    let sum = 0;
    for (const page of allData) {
      sum += page?.data?.length || 0;
    }
    return sum;
  };
  const GetPrefecture = (code) => {
    const name = PREFECTURE_OPTIONS?.find((pre) => pre.value === code)?.label;
    return name;
  };
  useEffect(() => {
    if (window.localStorage.getItem("articalTab")) {
      setSelectedMenu(window.localStorage.getItem("articalTab"));
    }
  }, []);
  useEffect(() => {
    setSelectedMenu("0");
  }, [city]);

  const {
    data: articles,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery<any, any>(
    ["getArticleList", selectedMenu, page, pageSize, city],
    GetArticleList,
    {
      initialData: {
        pages: [{ ...initialData }],
        pageParams: [],
      },
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage: any, allData) => {
        const gotCount = getCount(allData);
        const totalCount = lastPage.count;

        if (gotCount < totalCount) {
          return allData.length + 1;
        }
      },
    }
  );
  useEffect(() => {
    if (isFetching && initialFetch) {
      setInitialFetch(false);
    }
  }, [isLoading]);

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <StyledLink>{t("Previous")}</StyledLink>;
    }
    if (type === "next") {
      return <StyledLink>{t("Next")}</StyledLink>;
    }
    return originalElement;
  }
  useEffect(() => {
    setPage(1);
  }, [selectedMenu]);

  if (articles && articles?.pages[0]?.count === 0 && !isFetching) {
    if (router.pathname.startsWith("/articles")) {
      return (
        <EmptyWrapper>
          <Empty
            description={
              <StyledEmptyMessage>
                {t("No data available for display.")}
              </StyledEmptyMessage>
            }
          />
        </EmptyWrapper>
      );
    }
    return <></>;
  }

  if (router.pathname.startsWith("/articles") && initialFetch) {
    return (
      <Wrapper>
        <Row>
          <Skeleton active paragraph={{ rows: 6 }} />
        </Row>
      </Wrapper>
    );
  }

  const handleSelect = (value) => {
    setSelectedMenu(value);
    const view = process.browser && document.getElementById("content");
    if (view) {
      const yOffset = -200;
      const y = view.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <>
      <Wrapper>
        <Row className="select-wrapper">
          <StyledTitle>{t("Browse articles")}</StyledTitle>
          <SelectWrapper id={"select_id"}>
            <Select
              onChange={handleSelect}
              defaultValue={t("all")}
              suffixIcon={<DownIcon />}
              dropdownClassName="article-select"
              getPopupContainer={() => document.getElementById("select_id")}
            >
              {tags &&
                tags?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </SelectWrapper>
          {showButtons && articles?.pages[0]?.count > 12 && (
            <PaginationWrapper isTopPagination={true} margin="0px 0px 27px 0px">
              <Pagination
                defaultPageSize={pageSize || 12}
                showSizeChanger={false}
                itemRender={itemRender}
                pageSize={pageSize || 12}
                onChange={(page) => {
                  fetchNextPage();
                  setPage(page);
                }}
                total={articles?.pages[0]?.count}
                current={page}
              />
            </PaginationWrapper>
          )}
        </Row>
        <MainContainer>
          <Sider
            city={city}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
          <Content id="content">
            {isFetching ? (
              <LoadingWrapper sm={router.pathname.startsWith("/articles")}>
                <Spin size="large" />
              </LoadingWrapper>
            ) : (
              <ArticleContainer>
                {articles &&
                  spreadArray(articles?.pages)?.map((item, index) => (
                    <ArticleCard
                      slug={item.slug}
                      key={index}
                      hasVideo={item.hasVideo}
                      image={item.coverImage}
                      title={item.title}
                      name={GetPrefecture(item?.city?.toString())}
                    />
                  ))}
              </ArticleContainer>
            )}
            <ShowMoreButtonWrapper>
              {router.pathname.startsWith("/articles") && (
                <PaginationWrapper margin="27px 0px 0px 0px">
                  {articles?.pages[0]?.count > 12 && (
                    <Pagination
                      defaultPageSize={pageSize || 12}
                      showSizeChanger={false}
                      itemRender={itemRender}
                      pageSize={pageSize || 12}
                      onChange={(page) => {
                        fetchNextPage();
                        setPage(page);
                      }}
                      total={articles?.pages[0]?.count}
                      current={page}
                    />
                  )}
                </PaginationWrapper>
              )}

              {showButtons ? (
                articles?.pages[0]?.count !=
                  spreadArray(articles?.pages)?.length && (
                  <StyledFetch
                    loading={isLoading || isFetching}
                    onClick={() => {
                      fetchNextPage();
                    }}
                    type="primary"
                  >
                    {t("Show Other Articles")}
                  </StyledFetch>
                )
              ) : (
                <>
                  {articles?.pages[0]?.count > 6 && (
                    <Button
                      onClick={() => router.push("/articles")}
                      type="primary"
                    >
                      {t("Show Other Articles")}
                    </Button>
                  )}
                </>
              )}
            </ShowMoreButtonWrapper>
          </Content>
        </MainContainer>
      </Wrapper>
    </>
  );
};
